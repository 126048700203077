<template lang="pug">
TableV2(
  :isViewAdd="false"
  :isLoading="isLoad"
  :items="items.results || []"
  :headers="fields"
  :pageCount="items.count"
  :currentPage="items.current"
  labelKeyAdd="addCrewingCompany"
  :querySearch="getCrewingCompaniesList"
  :actions="actions")
  BackOfficeCrewingCompaniesAdd(
    @getCrewingCompanies="getCrewingCompaniesList()"
    actionType="add"
    slot="AddBlock")
</template>

<script>
import BackOfficeCrewingCompaniesAdd from '@/views/BackOffice/BackOfficeCrewing/BackOfficeCrewingCompanies/BackOfficeCrewingCompaniesForm.vue'
import { mapActions } from 'vuex'
import { checkAccess } from '@/mixins/permissions'

export default {
  components: {
    BackOfficeCrewingCompaniesAdd
  },
  data () {
    return {
      fields: [
        { value: 'id', text: this.$t('id'), sortable: false },
        { value: 'name_ukr', text: this.$t('crewingCompany'), sortable: false },
        { value: 'type_company', text: this.$t('type'), sortable: false },
        { value: 'is_for_isc', text: this.$t('isForISC'), sortable: false },
        { value: 'event', text: this.$t('actions'), sortable: false, align: 'center' }
      ],
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.edit',
          action: (item) => this.$router.push({ path: `company/${item.id}/edit` }),
          color: 'blue',
          name: 'mdi-pen',
          isView: checkAccess('backOffice')
        },
        {
          id: 3,
          tooltip: 'tooltip.go',
          action: (item) => {
            this.$router.push(
              {
                name: 'crewing-managers-backoffice',
                params: { ...this.$route.params, companyID: item.id }
              }
            )
          },
          color: 'blue',
          name: 'mdi-arrow-right',
          isView: true
        }
      ],
      items: {},
      isLoad: false
    }
  },
  beforeMount () {
    let query = { ...this.$route.query }
    if (Object.keys(query).includes('crewing_company')) {
      delete this.$route.query.crewing_company
      this.$router.replace({ query: { id: query.crewing_company, ...this.$route.query } })
    }
  },
  methods: {
    ...mapActions(['getCrewingCompanies']),
    getCrewingCompaniesList (filter) {
      this.isLoad = true
      const query = new URLSearchParams({
        ...filter,
        group: this.$route.params.groupID
      })
      this.getCrewingCompanies(query).then(response => {
        if (response.code === 200) this.items = response.data
      }).finally(() => { this.isLoad = false })
    }
  }
}
</script>
